import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { Actions } from '@src/store/enums/StoreEnums'

import store from '@src/store' // eslint-disable-line import/no-cycle
import { AuthGetters } from '@src/store/enums/AuthEnums'

// Layouts
import Auth from '@src/layout/AuthLayout.vue' // preload the authLayout

// Views
// Import most used urls directly (Login, Dashboard)
// The rest should be dynamically imported whenever needed

import Login from '../views/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Auth,
    children: [
      {
        name: 'root',
        path: '/',
        redirect: '/login'
      },
      {
        name: 'logginn',
        path: '/logginn',
        redirect: '/login'
      },
      {
        name: 'login',
        path: '/login',
        component: Login
      }
    ]
  },
  {
    path: '/',
    component: () => import('@src/layout/DefaultLayout.vue'),
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('@src/views/Dashboard.vue'),
        meta: { requiresAuth: true }
      },
      // Groups
      {
        name: 'groups',
        path: '/groups',
        component: () => import('@src/views/groups/GroupsIndex.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'group-view',
        path: '/groups/:groupId',
        component: () => import('@src/views/groups/group/GroupView.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'group-edit',
        path: '/groups/:groupId/edit',
        component: () => import('@src/views/groups/group/GroupEdit.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'group-setups',
        path: '/groups/:groupId/setups',
        component: () => import('@src/views/groups/group/GroupSetupsIndex.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'group-setup-edit',
        path: '/groups/:groupId/setups/:groupSetupId',
        component: () => import('@src/views/groups/group/setup/GroupSetupEdit.vue'),
        meta: { requiresAuth: true }
      },
      // Items
      {
        name: 'items',
        path: '/items',
        component: () => import('@src/views/items/ItemsIndex.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-view',
        path: '/items/:itemId',
        component: () => import('@src/views/items/item/ItemView.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-edit',
        path: '/items/:itemId/edit',
        component: () => import('@src/views/items/item/ItemEdit.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-edit-files',
        path: '/items/:itemId/files',
        component: () => import('@src/views/items/item/ItemFiles.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-edit-floorplans',
        path: '/items/:itemId/plans',
        component: () => import('@src/views/items/item/ItemPlans.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-edit-images',
        path: '/items/:itemId/images',
        component: () => import('@src/views/items/item/ItemImages.vue'),
        meta: { requiresAuth: true }
      },
      // ItemTemplates
      {
        name: 'item-templates',
        path: '/items/templates',
        component: () => import('@src/views/itemTemplates/ItemTemplatesIndex.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-templates-view',
        path: '/items/templates/:itemId',
        component: () => import('@src/views/itemTemplates/itemTemplate/ItemTemplateView.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-templates-edit',
        path: '/items/templates/:itemId/edit',
        component: () => import('@src/views/itemTemplates/itemTemplate/ItemTemplateEdit.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-templates-edit-files',
        path: '/items/templates/:itemId/files',
        component: () => import('@src/views/itemTemplates/itemTemplate/ItemTemplateFiles.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-templates-edit-floorplans',
        path: '/items/templates/:itemId/plans',
        component: () => import('@src/views/itemTemplates/itemTemplate/ItemTemplatePlans.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'item-templates-edit-images',
        path: '/items/templates/:itemId/images',
        component: () => import('@src/views/itemTemplates/itemTemplate/ItemTemplateImages.vue'),
        meta: { requiresAuth: true }
      },
      // Users
      {
        name: 'users',
        path: '/users',
        component: () => import('@src/views/users/UsersIndex.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'user-view',
        path: '/users/:userId',
        component: () => import('@src/views/users/user/UserView.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'user-edit',
        path: '/users/:userId/edit',
        component: () => import('@src/views/users/user/UserEdit.vue'),
        meta: { requiresAuth: true }
      },
      // Apis
      {
        name: 'apis-sync',
        path: '/apis/sync',
        component: () => import('@src/views/apis/Sync.vue'),
        meta: { requiresAuth: true }
      },
      // Logs
      {
        name: 'logs',
        path: '/logs',
        component: () => import('@src/views/logs/LogsIndex.vue'),
        meta: { requiresAuth: true }
      },
      // Settings
      {
        name: 'settings',
        path: '/settings',
        component: () => import('@src/views/settings/SettingsIndex.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    redirect: '/login'
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)

  const isUserAuthenticated = store.getters[AuthGetters.GET_ALL]

  if (requiresAuth && !isUserAuthenticated) {
    store.dispatch(Actions.VERIFY_AUTH) // Initializes the verification of the user
  }

  // If the user is logged in and the route is not for logged in users...
  if (!requiresAuth && isUserAuthenticated) {
    return next('/dashboard')
  }

  return next()
})

export default router
