// Global translations
//
// Keep everything in lowercase, capitalize with filters in views.
// Only single words in this global file - all sentences in individual files
//
// Paramters
// In view: trans('global.translation_key', { key: 2 })
// In trans: translation_key: 'This number will be dynamically inserted -> :key'
//
// Plurals
// In view: tranc_choice('global.translation_key', 1)
// In trans: translation_key: '{1} This will appear when transChoice uses param 1 | {*} Every other number'

/* eslint-disable max-len */
module.exports = {
  register: '{1}registrer|{*}registreringer',
  email: '{0}epost|{1}eposten|{2}eposter|{*}epostene',
  password: 'passord',
  user: '{0}bruker|{1}brukeren|{*}brukere',
  superuser: '{0}superbruker|{1}superbrukeren|{*}superbrukere',
  shortcut: '{1}snarvei|{*}snarveier',
  firstname: '{0}fornavn|{1}fornavnet|{*}fornavn',
  middlename: '{0}mellomnavn|{1}mellomnavnet|{*}mellomnavn',
  lastname: '{0}etternavn|{1}etternavnet|{*}etternavn',
  phone: '{1}telefon|{*}telefoner',
  avatar: '{1}avatar|{*}avatarer ',
  profileimage: '{1}profilbilde|{*}profilbilder',
  image: '{0}bilde|{1}bilde|{*}bilder',
  group: '{0}gruppe|{1}gruppen|{*}grupper',
  groupname: '{1}gruppenavn|{*}gruppenavn',
  description: '{0}beskrivelse|{1}beskrivelsen|{*}beskrivelser',
  save: '{0}lagre|{1}lagret|{*}lagrer',
  update: '{0}oppdater|{1}oppdatere|{2}oppdaterer|{*}oppdaterte',
  updated: '{0}oppdatert|{1}oppdatert|{2}oppdaterte|{*}oppdaterte',
  name: '{0}navn|{1}navnet|{2}navn|{*}navnene',
  action: '{0}handling|{1}handlingen|{*}handlinger',
  dashboard: '{0}dashboardet|{1}dashboard|{*}dashboard',
  profile: '{1}profil|{*}profiler',
  account: '{0}konto|{1}kontoen|{2}kontoer|{*}kontoene',
  role: '{0}rolle|{1}rollen|{2}roller|{*}rollene',
  settings: '{0}innstilling|{1}innstillingen|{2}innstillinger|{*}innstillingene',
  edit: '{0}rediger|{1}redigere|{*}redigerer',
  rights: '{1}rettighet|{*}rettigheter',
  admin: '{0}administrator|{1}administratoren|{*}administratorer',
  tools: '{1}verktøy|{*}verktøy',
  synchronize: '{1}synkroniser|{*}synkroniser',
  time: '{1}kl.|{*}kl.',
  system: '{0}system|{1}systemet|{*}systemer',
  events: '{0}hendelse|{1}hendelsen|{*}hendelser',
  success: '{0}suksess|{1}suksessen|{*}suksesser',
  error: '{0}feil|{1}feilen|{*}feil',
  warning: '{0}advarsel|{1}advarselen|{*}advarslene',
  info: '{0}info|{1}infoen|{*}infoene',
  access: '{0}tilgang|{1}tilgangen|{*}tilgangene',
  hello: '{0}hei|{1}heien|{*}heiene',
  frontend: '{0}frontend|{1}frontenden|{*}frontendene',
  backend: '{0}backend|{1}backenden|{*}backendene',
  both: '{0}begge|{1}begge|{*}begge',
  disable: '{0}deaktiver|{1}deaktivere|{*}deaktiverer',
  disabled: '{0}deaktivert|{1}deaktiverte|{*}deaktiverte',
  delete: '{0}slett|{1}slette|{*}sletter',
  deleted: '{0}slette|{1}slettet|{*}slettede',
  cancel: '{0}avbryt|{1}avbryter|{*}avbryte',
  activate: '{0}aktiver|{1}aktiverer|{*}aktivere',
  project: '{0}prosjekt|{1}prosjektet|{*}prosjekter',
  company: '{0}firma|{1}firmaet|{*}firmaer',
  connection: '{0}tilkobling|{1}tilkoblingen|{*}tilkoblinger',
  connect: '{0}koble|{1}kobler|{*}koble',
  complete: '{0}fullfør|{1}fullfører|{*}fullfør',
  team: '{0}team|{1}teamet|{*}teams',
  member: '{0}medlem|{1}medlemmet|{*}medlemmer',
  active: '{0}aktiv|{1}aktiv|{*}aktive',
  private: '{0}privat|{1}privat|{*}private',
  developer: '{0}utvikler|{1}utvikleren|{*}utviklere',
  permission: '{0}tillatelse|{1}tillatelsen|{*}tillatelser',
  look: '{0}se|{1}ser|{*}seende',
  create: '{0}lag|{1}lage|{*}lager',
  created: '{0}opprette|{1}opprettet|{2}opprettelser|{*}opprettelsene',
  welcome: '{0}velkommen|{1}velkommen|{*}velkomne',
  owner: '{0}eier|{1}eieren|{*}eierene',
  invitation: '{0}invitasjon|{1}invitasjonen|{*}invitasjonene',
  accept: '{0}aksepter|{1}aksepten|{*}akseptene',
  decline: '{0}avslå|{1}avslåelsen|{*}avslåelsene',
  yes: '{0}ja|{1}ja|{*}ja',
  no: '{0}nei|{1}nei|{*}nei',
  none: '{0}ingen|{1}ingen|{2}ingen|{*}ingen',
  template: '{0}mal|{1}malen|{2}maler|{*}malene',
  product: '{0}produkt|{1}produktet|{*}produkter',
  customer: '{0}kunde|{1}kunden|{*}kunder',
  session: '{0}økt|{1}økten|{*}økter',
  title: '{0}tittel|{1}tittelen|{*}titler',
  date: '{0}dato|{1}datoen|{*}datoer',
  successful: '{0}vellykket|{1}vellykket|{*}vellykkede',
  unknown: '{0}ukjent|{1}ukjent|{*}ukjente',
  id: '{0}id|{1}iden|{*}ider',
  detail: '{0}detalj|{1}detaljen|{*}detaljer',
  status: '{0}status|{1}statusen|{*}statuser',
  all: '{0}alle|{1}alle|{*}alle',
  loading: '{0}last|{1}lasten|{*}laster',
  task: '{0}oppgave|{1}oppgaven|{*}oppgaver',
  log: '{0}logg|{1}loggen|{*}logger',
  position: '{0}posisjon|{1}posisjonen|{*}posisjoner',
  order: '{0}rekkefølge|{1}rekkefølgen|{*}rekkefølger',
  standard: '{0}standard|{1}standard|{*}standard',
  executed: '{0}utført|{1}utført|{*}utførte',
  render: '{0}render|{1}renderen|{*}rendere',
  layer: '{0}lag|{1}laget|{*}lagene',
  earlier: '{0}tidligere|{1}tidligere|{*}tidligere',
  from: '{0}fra|{1}fra|{*}fra',
  to: '{0}til|{1}til|{*}til',
  superadmin: '{0}superadministrator|{1}superadministratoren|{*}superadministratorer',
  guest: '{0}gjest|{1}gjesten|{2}gjester|{*}gjestene',
  language: '{0}språk|{1}språket|{2}språk|{*}språkene',
  level: '{0}nivå|{1}nivået|{2}nivå|{*}nivåene',
  choice: '{0}valg|{1}valget|{2}valg|{*}valgene',
  maplayer: '{0}kartlag|{1}kartlaget|{2}kartlag|{*}kartlagene',
  unit: '{0}enhet|{1}enheten|{2}enheter|{*}enhetene',
  apartmentBuilding: '{0}blokk|{1}blokken|{2}blokker|{*}blokkene',
  area: '{0}område|{1}området|{2}områder|{*}områdene',
  building: '{0}bygning|{1}bygningen|{2}bygninger|{*}bygningene',
  type: '{0}type|{1}typen|{2}typer|{*}typene',
  progress: '{0}fremdrift|{1}fremdriften|{2}fremdrifter|{*}fremdriftene',
  house: '{0}hus|{1}huset|{2}husene|{*}hus',
  apartment: '{0}leilighet|{1}leiligheten|{2}leilighetene|{*}leiligheter',
  cabin: '{0}hytte|{1}hytta|{2}hyttene|{*}hytter',
  coming: '{0}komme|{1}kommer|{2}kommer|{*}kommer',
  available: '{0}tilgjengelig|{1}tilgjengelig|{2}tilgjengelig|{*}tilgjengelig',
  reserved: '{0}reservert|{1}reservert|{2}reservert|{*}reservert',
  sold: '{0}solgt|{1}solgt|{2}solgt|{*}solgt',
  overview: '{0}oversikt|{1}oversikten|{2}oversiktene|{*}oversikter',
  media: '{0}media|{1}mediet|{2}mediene|{*}medier',
  general: '{0}generell|{1}generelt|{2}generelle|{*}generelle',
  document: '{0}dokument|{1}dokumentet|{2}dokumenter|{*}dokumentene',
  floorplan: '{0}plantegning|{1}plantegningen|{2}plantegninger|{*}plantegningene',
  file: '{0}fil|{1}filen|{2}filer|{*}filene',
  size: '{0}størrelse|{1}størrelsen|{2}størrelser|{*}størrelsene',
  api: '{0}api|{1}apiet|{2}apier|{*}apiene',
  ignore: '{0}overse|{1}overser|{2}overså|{*}oversett',
  count: '{0}antall|{1}antallet|{2}antall|{*}antallene',
  example: '{0}eksempel|{1}eksempelet|{2}eksempler|{*}eksemplene',
  result: '{0}resultat|{1}resultatet|{2}resultat|{*}resultatene',
  design: '{0}visning|{1}visningen|{2}visninger|{*}visningene',
  setup: '{0}oppsett|{1}oppsettet|{2}oppsett|{*}oppsettene',
  slug: '{0}slug|{1}sluggen|{2}slugger|{*}sluggene',
  groupSetup: '{0}oppsett|{1}oppsettet|{2}oppsett|{*}oppsettene',
  new: '{0}ny|{1}ny|{2}nye|{*}nye',
  remaining: '{0}gjenstå|{1}gjenstår|{2}gjenstående|{*}gjenstående',
  ready: '{0}klar|{1}klart|{2}klare|{*}klare',
  waiting: '{0}vente|{1}venter|{2}vemtet|{*}venta'
}
