module.exports = {
  breadcrumb: 'Innstillinger',
  toolbar: {
    title: 'Tilpass applikasjonen'
  },
  forms: {
    global: {
      title: 'Globale innstillinger',
      fields: {
        showGroups: {
          title: 'Vis grupper',
          description: 'Skal de forskjellige vinklene (øst, vest, sørøst, sørvest, osv) vises?'
        },
        showFilters: {
          title: 'Vis filter',
          description: 'Skal filter vises?'
        },
        showTableSorting: {
          title: 'Tabellsortering',
          description: 'Skal sorteringsalternativer for tabellen vises?'
        },
        showTable: {
          title: 'Vis tabell',
          description: 'Skal tabellen vises?'
        }
      },
      success: {
        title: 'Innstillingen ble oppdatert!',
        message: ':settingkey ble oppdatert'
      }
    },
    api: {
      title: 'API',
      btn: 'Lagre',
      fields: {
        wpSyncUrl: {
          title: 'URL til Wordpress sync API endpoint',
          placeholder: 'https://www.addressen-til-websiden.no/wp-json/nyghp/v1/start',
          errors: {
            minLength: 'URLen må være lenger enn 8 tegn',
            url: 'API endpoint addressen må være en gyldig URL'
          }
        }
      },
      success: {
        title: 'API innstillingen ble oppdatert',
        message: ':apisettingkey ble oppdatert'
      }
    }
  }
}
