// eslint-disable-next-line no-shadow
enum SettingsGetters {
  GET_ALL = 'getAll',
  GET_SETTINGS_STATE = 'getSettingsState'
}

// eslint-disable-next-line no-shadow
enum SettingsActions {
  GET_SETTINGS_USERDATA = 'getSettingsData',
}

// eslint-disable-next-line no-shadow
enum SettingsMutations {
  SET_SETTINGS_CONFIG = 'setSettingsConfig',
  RESET_SETTINGS_CONFIG = 'resetSettingsConfig',
  OVERRIDE_SETTINGS_CONFIG = 'overrideSettingsConfig',
  SET_SETTINGS_VALUE = 'setSettingsValue'
}

export { SettingsGetters, SettingsActions, SettingsMutations }
