import { ref } from 'vue'
import type { Ref } from 'vue'
import apiConfig from '@src/core/config/apiConfig'
import axios from 'axios'
import objectPath from 'object-path'
import fb from '@src/core/config/firebase'
// import { useStore } from 'vuex'
import { AuthMutations } from '@src/store/enums/AuthEnums'
import store from '@src/store' // eslint-disable-line import/no-cycle

export interface useApiAsyncReturn {
  state: Ref<any>
  isReady: Ref<boolean>
  isLoading: Ref<boolean>
  error: unknown,
  useApi: (...args: any[]) => Promise<any>
}

export interface useApiAsyncOptions {
  resetOnExecute?: boolean // Sets the state to initialState before executing the promise.
  apiVersion?: number
}

export interface useApiAsyncAttr {
  apiToken: string
}

export interface useApiAsyncParams {
  api: any, // will be object
  data?: any
}

const objPath = objectPath.create({ includeInheritedProps: true })

/**
 * Reactive async state.
 * @param params          The arguments
 * @param initialState    The initial state, used until the first evaluation finishes
 * @param options
 */
export function useApiAsync (
  attr?: useApiAsyncAttr,
  options?: useApiAsyncOptions,
): useApiAsyncReturn {
  const initialState = ref({})
  const {
    resetOnExecute = true,
    apiVersion = apiConfig.version
  } = options ?? {}

  const state = ref(initialState)
  const isReady = ref(false)
  const isLoading = ref(false)
  const error = ref<unknown | undefined>(undefined)
  const retries = ref(0)
  // const store = useStore()

  async function useApi (params: useApiAsyncParams) {
    const { // destructed values with default values
      api = apiConfig.misc.fallback,
      data = {}
    } = params ?? {}

    console.log('[apiAsync] PARAMS for API: ', params)

    let apiToken = attr !== undefined && attr.apiToken !== undefined && attr.apiToken !== ''
    ? attr.apiToken
    : objPath.has(store, 'state.AuthModule.user.accessToken') // Try to fetch from store if not defined
      ? store.state.AuthModule.user.accessToken
      : ''

    if (apiToken === '') {
      throw {
        code: 'auth/missing-token',
        message: 'Missing API token',
      }
    }

    if (resetOnExecute) state.value = initialState
    
    error.value = undefined
    isReady.value = false
    isLoading.value = true

    console.log('[apiAsync] Connecting to: ', `${ apiConfig.url }/${ apiVersion }/${ api.url }`)
    
    // await new Promise((r) => setTimeout(r, 1000)) // Development timeout

    const _promise = axios({
      method: api.method,
      headers: {
        Authorization: `Bearer ${ apiToken }`,
        'Content-Type': 'application/json'
      },
      url: `${ apiConfig.url }/${ apiVersion }/${ api.url }`,
      data
    })

    try {
      const result = await _promise
      console.log('[apiAsync] Result: ', result)
      state.value = objPath.get(result, 'data', {})
      isReady.value = true

      return state.value
    } catch (e: any) {
      console.log('[apiAsync] Error: ', objPath.get(e, 'response.data', {})) // eslint-disable-line max-len
      const errorObj = Object.keys(objPath.get(e, 'response.data', {})).length > 0
        ? objPath.get(e, 'response.data')
        : {
            code: 'misc/unknown',
            message: 'A unknown error occurred (apiAsync composable)',
            response: e.response
          }

      if (errorObj.code === 'auth/id-token-expired') {
        const auth = fb.auth.getAuth()
        const user: any = auth.currentUser
        return fb.auth.getIdToken(user, true).then((res: any) => {
          retries.value += 1
          if (retries.value < 5) {
            console.log('Refreshed the ID-token - retrying', res)
            store.commit(AuthMutations.SET_NEW_TOKEN, res)
            apiToken = res
            return useApi(params)
          }

          throw {
            code: 'auth/too-many-retries',
            message: 'Unable to refresh auth-token.',
          }
        }).catch((err) => {
          console.log('Problems getting the new id-token, quitting', err)
          throw errorObj
        })
      }

      throw errorObj
    } finally {
      isLoading.value = false
    }
  }

  return {
    state,
    isReady,
    isLoading,
    error,
    useApi
  }
}
