// eslint-disable-next-line no-shadow
enum ToastGetters {
  GET_TOAST = 'getToast'
}

// eslint-disable-next-line no-shadow
enum ToastActions {
  
}

// eslint-disable-next-line no-shadow
enum ToastMutations {
  SET_TOAST = 'setToast',
  DELETE_TOAST = 'deleteToast'
}

export { ToastGetters, ToastActions, ToastMutations }
