
  import { defineComponent, computed, onMounted, onUnmounted } from 'vue'
  import { useStore } from 'vuex'
  import { Actions } from '@src/store/enums/StoreEnums'

  export default defineComponent({
    name: 'AuthLogin',
    setup () {
      const store = useStore()
      const errors = computed(() => store.state.auth.errors)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */ // TODO: Fix process.env error in ts
      const backgroundImage = computed(() => `${ process.env.BASE_URL }media/custom/login_bg.jpg`)

      onMounted(() => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'bg-body')
      })

      onUnmounted(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'bg-body')
      })

      return {
        errors,
        backgroundImage
      }
    }
  })
