import { createStore } from 'vuex'
import { config } from 'vuex-module-decorators'

import BodyModule from '@src/store/modules/BodyModule'
import AuthModule from '@src/store/modules/AuthModule' // eslint-disable-line import/no-cycle
import BreadCrumbsModule from '@src/store/modules/BreadcrumbsModule'
import ConfigModule from '@src/store/modules/ConfigModule'
import SettingsModule from '@src/store/modules/SettingsModule'
import ToolbarModule from '@src/store/modules/ToolbarModule'
import ThemeModeModule from '@src/store/modules/ThemeModeModule'
import ToastModule from '@src/store/modules/ToastsModule'

config.rawError = true

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadCrumbsModule,
    ConfigModule,
    SettingsModule,
    ToolbarModule,
    ThemeModeModule,
    ToastModule
  }
})

export default store
