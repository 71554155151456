
  import { computed, ref, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'
  import useFormValidation from '@src/use/formValidation'

  import useVuelidate from '@vuelidate/core'
  import { required, email, minLength } from '@vuelidate/validators'

  import { Actions, Mutations } from '@src/store/enums/StoreEnums'

  import fb from '@src/core/config/firebase'

  export default {
    name: 'LoginLayout',
    setup () {
      const store = useStore()
      const router = useRouter()

      onBeforeMount(() => {
        // store.commit(SET_ERROR, {}) // Remove all previous error-messages?
      })

      const submitted = ref(false)
      const submitButtonAnimation = ref(false)

      const formErrors = computed(() => store.state.AuthModule.errors) // computed vuex replaces mapstate
      const formEmail = ref('')
      const formPassword = ref('')

      const formErrorMessages = computed(() => ({
        'auth/user-disabled': trans('auth.login.messages.userDisabled'),
        'auth/user-not-found': trans('auth.login.messages.wrongUsernameOrPassword'),
        'auth/wrong-password': trans('auth.login.messages.wrongUsernameOrPassword'),
        'auth/too-many-requests': trans('auth.login.messages.tooManyRequests'),
        'auth/automatically-logged-out': trans('auth.login.messages.autologout'),
        'auth/operation-not-allowed': trans('auth.login.messages.providerDisabled'),
        'auth/configuration-not-found': trans('auth.login.messages.noConfigFound'),
        'auth/network-request-failed': trans('auth.login.messages.networkError'),
        'auth/missing-user-in-db': trans('auth.login.messages.missingUserInDB'), // Custom auth
        'auth/missing-token': trans('auth.login.messages.noToken'), // Custom auth
        'custom/api-unreachable': trans('auth.login.messages.apiUnreachable'), // Custom
        'misc/unknown-error': trans('auth.login.messages.unknownError')
      }))

      const firebaseErrors = () => formErrors.value.message === undefined // Custom validation rule

      const validationRules = {
        email: {
          required,
          email,
          firebaseErrors
        },
        password: {
          required,
          minLength: minLength(8),
          firebaseErrors
        }
      }

      const v$: any = useVuelidate(
        validationRules,
        {
          email: formEmail,
          password: formPassword
        }
      )

      const { validateState } = useFormValidation(v$, submitted)

      const onSubmit = async () => {
        store.commit(Mutations.SET_ERROR, {}) // Remove all previous error-messages
        submitted.value = true
        submitButtonAnimation.value = true

        v$.value.$touch()
        if (Object.keys(v$.value.$errors).length > 0) {
          submitButtonAnimation.value = false
          return // Found errors, will be shown in form - quit
        }

        await store.dispatch(Actions.LOGOUT)

        // Login with Firebase Auth Service
        const firebaseLoginResult = await fb.auth.signInWithEmailAndPassword(
          fb.auth.getAuth(),
          v$.value.email.$model,
          v$.value.password.$model
        )
          .then((user: any) => user)
          .catch((err: any) => {
            console.log('[FirebaseLoginResult ERROR:', err)
            store.commit(Mutations.SET_ERROR, { message: err.code })
            return err
          })

        // Fetch additional required userdata from Firestore DB (users collection)
        const validUserData = firebaseLoginResult.code === undefined
          ? await store.dispatch(Actions.LOGIN, { user: firebaseLoginResult.user, mode: 'manually' }) // eslint-disable-line object-property-newline, max-len
            .then((res: any) => res).catch((err: any) => {
              console.log('ERROR from VUEX LOGIN:', err)
              return err
            })
          : firebaseLoginResult // Hand off the ".code" to the IF statement

        if (validUserData.code !== undefined) {
          await store.dispatch(Actions.LOGOUT)
          store.commit(Mutations.SET_ERROR, { message: validUserData.code })
          submitButtonAnimation.value = false
          return
        }

        router.push('/dashboard').catch((err: any) => console.log(err))
        // root.$router.push('/dashboard').catch((err: any) => console.log(err))
      }

      return {
        formEmail,
        formPassword,
        formErrors,
        formErrorMessages,
        validateState,
        onSubmit,
        v$,
        trans,
        transChoice,
        capitalize,
        submitButtonAnimation
      }
    }
  }
