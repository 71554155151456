const apiConfig = {
  // url: 'http://192.168.68.54:3000', // For network development @ home with network support
  // url: 'http://127.0.0.1:3000', // For single computer development
  url: process.env.VUE_APP_LOCAL_DEVELOPMENT === 'enabled' ? 'http://127.0.0.1:3000' : process.env.VUE_APP_API_URL,
  version: 'v1',
  misc: {
    fallback: {
      method: 'POST',
      url: 'misc/fallback'
    }
  },
  backend: {
    groups: {
      create: {
        method: 'POST',
        url: 'backend/groups/create'
      },
      get: {
        method: 'POST',
        url: 'backend/groups/get'
      },
      getAll: {
        method: 'POST',
        url: 'backend/groups/getAll'
      },
      update: {
        method: 'POST',
        url: 'backend/groups/update'
      },
      updateImage: {
        method: 'POST',
        url: 'backend/groups/updateImage'
      },
      setDefault: {
        method: 'POST',
        url: 'backend/groups/setDefault'
      }
    },
    groupSetups: {
      create: {
        method: 'POST',
        url: 'backend/groupSetups/create'
      },
      get: {
        method: 'POST',
        url: 'backend/groupSetups/get'
      },
      getAll: {
        method: 'POST',
        url: 'backend/groupSetups/getAll'
      },
      getQuery: {
        method: 'POST',
        url: 'backend/groupSetups/getQuery'
      },
      update: {
        method: 'POST',
        url: 'backend/groupSetups/update'
      },
      updateImage: {
        method: 'POST',
        url: 'backend/groupSetups/updateImage'
      },
      setDefault: {
        method: 'POST',
        url: 'backend/groupSetups/setDefault'
      }
    },
    groupItems: {
      create: {
        method: 'POST',
        url: 'backend/groupItems/create'
      },
      get: {
        method: 'POST',
        url: 'backend/groupItems/get'
      },
      update: {
        method: 'POST',
        url: 'backend/groupItems/update'
      },
      getQuery: {
        method: 'POST',
        url: 'backend/groupItems/getQuery'
      },
    },
    items: {
      create: {
        method: 'POST',
        url: 'backend/items/create'
      },
      update: {
        method: 'POST',
        url: 'backend/items/update'
      },
      get: {
        method: 'POST',
        url: 'backend/items/get'
      },
      getAll: {
        method: 'POST',
        url: 'backend/items/getAll'
      },
      deleteField: {
        method: 'POST',
        url: 'backend/items/deleteField'
      },
      processUploadedFiles: {
        method: 'POST',
        url: 'backend/items/processUploadedFiles'
      },
      createTemplate: {
        method: 'POST',
        url: 'backend/items/createTemplate'
      },
      getQuery: {
        method: 'POST',
        url: 'backend/items/getQuery'
      },
      copy: {
        method: 'POST',
        url: 'backend/items/copy'
      },
      batchDelete: {
        method: 'POST',
        url: 'backend/items/batchDelete'
      }
    },
    itemTemplates: {
      create: {
        method: 'POST',
        url: 'backend/itemTemplates/create'
      },
      update: {
        method: 'POST',
        url: 'backend/itemTemplates/update'
      },
      get: {
        method: 'POST',
        url: 'backend/itemTemplates/get'
      },
      getAll: {
        method: 'POST',
        url: 'backend/itemTemplates/getAll'
      },
      deleteField: {
        method: 'POST',
        url: 'backend/itemTemplates/deleteField'
      },
      processUploadedFiles: {
        method: 'POST',
        url: 'backend/itemTemplates/processUploadedFiles'
      },
    },
    settings: {
      getAll: {
        method: 'POST',
        url: 'backend/settings/getAll'
      },
      update: {
        method: 'POST',
        url: 'backend/settings/update'
      }
    },
    api: {
      sync: {
        get: {
          method: 'POST',
          url: 'backend/api/get'
        },
        create: {
          method: 'POST',
          url: 'backend/api/create'
        }
      }
    },
    users: {
      login: {
        method: 'POST',
        url: 'backend/users/login'
      },
      logout: {
        method: 'POST',
        url: 'backend/users/logout'
      },
      get: {
        method: 'POST',
        url: 'backend/users/get'
      },
      create: {
        method: 'POST',
        url: 'backend/users/create'
      },
      getAll: {
        method: 'POST',
        url: 'backend/users/getAll'
      },
      update: {
        method: 'POST',
        url: 'backend/users/update'
      },
      delete: {
        method: 'POST',
        url: 'backend/users/delete'
      },
      activate: {
        method: 'POST',
        url: 'backend/users/activate'
      }
    },
    logs: {
      getAll: {
        method: 'POST',
        url: 'backend/logs/getAll'
      },
      getQuery: {
        method: 'POST',
        url: 'backend/logs/getQuery'
      }
    }
  }
}

export default apiConfig
