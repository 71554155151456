/*
 * Capitalize text
 * Install:
 * import { capitalize } from './core/helpers/textUtils'
 *
 * Usage:
 * capitalize(STRING)
 */

export const capitalize = (string: any) => {
  if (!string) return ''

  const value = string.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/*
 Truncate text
 Install:
 import { truncate } from './core/helpers/textUtils'
 Usage:
 truncate(STRING)
 */

export const truncate = (text = '', length = 20, clamp = '...') => {
  if (text === null || text === undefined) return ''
  if (text.length <= length) return text

  let tcText = text.slice(0, length - clamp.length)
  let last = tcText.length - 1

  while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1

  // Fix for case when text dont have any `space`
  last = last || length - clamp.length

  tcText = tcText.slice(0, last)

  return tcText + clamp
}

/*
  RandomString
  Install:
  import { randomString } from '@/core/helpers/textUtils'
  Usage:
  randomString()
  randomString('random', 32)
  */
export const randomString = (pretxt = '', length = 16) => {
  let txt = pretxt
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    txt += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return txt
}

/**
 * formatBytes
 * Install:
 * import { formatBytes } from '@/core/helpers/textUtils'
 * Usage:
 * formatBytes(bytes, decimals)
 * formatBytes(1024)       // 1 KB
 * formatBytes('1024')     // 1 KB
 * formatBytes(1234)       // 1.21 KB
 * formatBytes(1234, 3)    // 1.205 KB
 * formatBytes(0)          // 0 Bytes
 * formatBytes('0')        // 0 Bytes
 */

 export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

/**
  * safeString ()
  * @param {string} fixString
  * @param {boolean} toLowerCase
  * @return {string}
  *
  * Remember to update the code in the helper in the backend repo as well
  */
 export const safeString = (fixString: string, toLowerCase?: boolean) => {
  let validString = fixString.toString()
      .replace(/\s+/gu, '-') // Replace spaces with -
      .replace(/\?/gu, '-questionmark-') // Replace ? with '-questionmark-'
      .replace(/\+/gu, '-plus-') // Replace + with 'plus'
      .replace(/\//igu, '-slash-') // Replace / with '-slash-'
      .replace(/&/gu, '-and-') // Replace & with 'and'
      .replace(/=/gu, '-equals-') // Replace = with 'equals'
      .replace(/@/gu, '-at-') // Replace @ with '-at-'
      .replace(/\$/gu, '-dollar-') // Replace $ with '-dollar-'
      .replace(/§/gu, '-section-') // Replace § with '-section-'
      .replace(/-+/gu, '-') // Replace multiple - with single -
      .replace(/^-+/u, '') // Trim - from start of text
      .replace(/-+$/u, '') // Trim - from end of text
      .replace(/[^a-zA-Z 0-9,._();-]/g, '') // Remove ALL non accepted chars completely

  if (toLowerCase) validString = validString.toLowerCase()
  return validString
}
