import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d34f3b1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-form login-signin" }
const _hoisted_2 = { class: "text-center mb-5" }
const _hoisted_3 = { class: "fs-1" }
const _hoisted_4 = { class: "text-muted font-weight-semi-bold" }
const _hoisted_5 = { class: "mb-8 pt-10" }
const _hoisted_6 = { for: "login-email" }
const _hoisted_7 = ["state", "placeholder"]
const _hoisted_8 = {
  key: 0,
  id: "login-email-live-feedback",
  class: "text-danger"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { for: "input-password" }
const _hoisted_12 = ["state", "placeholder"]
const _hoisted_13 = {
  key: 0,
  id: "input-password-live-feedback",
  class: "text-danger"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "alert-text" }
const _hoisted_16 = { class: "form-group d-flex flex-wrap justify-content-between align-items-center" }
const _hoisted_17 = {
  id: "nyg_login_forgot",
  href: "#",
  class: "text-dark-60 text-hover-primary my-3 me-2"
}
const _hoisted_18 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString($setup.trans('auth.login.login')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString($setup.trans('auth.login.emailAndPassword')), 1)
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($setup.onSubmit && $setup.onSubmit(...args)), ["stop","prevent"]))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString($setup.capitalize($setup.transChoice('global.email', 0))), 1),
          _withDirectives(_createElementVNode("input", {
            id: "login-email",
            type: "email",
            class: _normalizeClass(["form-control form-control-solid h-auto py-5 px-6", { 'is-invalid': $setup.validateState('email') === false || $setup.formErrors.message !== undefined }]),
            name: "login-email",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.email.$model) = $event)),
            state: $setup.validateState('email'),
            placeholder: $setup.trans('auth.login.yourEmail'),
            "aria-describedby": "login-email-live-feedback"
          }, null, 10, _hoisted_7), [
            [_vModelText, $setup.v$.email.$model]
          ]),
          ($setup.validateState('email') === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                ($setup.formErrors.message === undefined)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($setup.trans('auth.login.messages.validEmailRequired')), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, _toDisplayString($setup.capitalize($setup.transChoice('global.password', 1))), 1),
          _withDirectives(_createElementVNode("input", {
            id: "input-password",
            type: "password",
            class: _normalizeClass(["form-control form-control-solid h-auto py-5 px-6", { 'is-invalid': $setup.validateState('password') === false || $setup.formErrors.message !== undefined }]),
            name: "input-password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.password.$model) = $event)),
            state: $setup.validateState('password'),
            placeholder: $setup.trans('auth.login.yourPassword'),
            "aria-describedby": "input-password-live-feedback"
          }, null, 10, _hoisted_12), [
            [_vModelText, $setup.v$.password.$model]
          ]),
          ($setup.validateState('password') === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                ($setup.formErrors.message === undefined)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString($setup.trans('auth.login.messages.passwordRequired')), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          role: "alert",
          class: _normalizeClass([{ show: $setup.formErrors.message !== undefined }, "alert fade alert-danger mt-8"])
        }, [
          _createElementVNode("div", _hoisted_15, _toDisplayString($setup.formErrorMessages[$setup.formErrors.message]), 1)
        ], 2),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("a", _hoisted_17, _toDisplayString($setup.trans('auth.login.forgotPassword')), 1),
          _createElementVNode("button", {
            ref: "nyg_login_submit_btn",
            class: "btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3",
            disabled: $setup.submitButtonAnimation
          }, [
            _createTextVNode(_toDisplayString($setup.submitButtonAnimation ? $setup.trans('auth.login.loggingIn') : $setup.trans('auth.login.login')) + " ", 1),
            _createElementVNode("span", {
              class: _normalizeClass(["spinner-border spinner-border-sm align-middle ms-2", $setup.submitButtonAnimation ? 'd-inline-block' : 'd-none'])
            }, null, 2)
          ], 8, _hoisted_18)
        ])
      ], 32)
    ])
  ]))
}