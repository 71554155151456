module.exports = {
  logout: 'Logg ut',
  myProfile: 'Min profil',
  index: {
    breadcrumb: 'Alle brukere',
    search: {
      title: 'Sorter etter:',
      placeholder: 'Søk etter brukere...'
    },
    toolbar: {
      export: {
        title: 'Eksporter'
      },
      addCustomer: {
        title: 'Legg til bruker'
      }
    }
  },
  view: {
    title: 'Brukerdetaljer',
    lastLogin: 'Sist innlogging'
  },
  edit: {
    deleted: {
      title: 'Denne brukeren er slettet',
      message: ':authuser slettet denne brukeren :datetime'
    }
  },
  forms: {
    create: {
      title: 'Legg til bruker',
      fields: {
        displayName: {
          title: 'Navn',
          placeholder: 'Skriv inn brukerens navn',
          errors: {
            required: 'Brukeren må ha et navn',
            minLength: 'Navnet må være lenger enn 2 bokstaver',
            alphaAndHyphenAndApostrophe: 'Brukernavn kan kun bestå av bokstaver og tall'
          }
        },
        email: {
          title: 'E-post',
          placeholder: 'Skriv inn brukerens epost',
          errors: {
            required: 'Brukeren må ha en epost',
            email: 'Eposten må være gyldig'
          }
        },
        password: {
          title: 'Passord',
          placeholder: 'Skriv inn et passord',
          errors: {
            required: 'Brukeren må ha et passord',
            minLength: 'Passordet må inneholde minst 8 tegn'
          }
        },
        level: {
          title: 'Brukernivå',
          placeholder: 'Velg et brukernivå',
          errors: {
            required: 'Brukeren må ha et brukernivå',
            levelChecker: 'Du kan ikke gi brukeren høyere nivå enn deg'
          }
        }
      },
      errorCodes: {
        'auth/email-already-exists': {
          title: 'Eksisterende bruker?',
          message: 'Denne eposten finnes allerede i systemet'
        }
      },
      success: {
        title: 'Brukeren ble opprettet',
        message: ':user ble lagt til som en :accounttype'
      }
    },
    edit: {
      title: 'Rediger brukerinformasjon',
      btn: 'Endre passord',
      fields: {
        displayName: {
          title: 'Navn',
          placeholder: 'Skriv inn brukerens navn',
          errors: {
            required: 'Brukeren må ha et navn',
            minLength: 'Navnet må være lenger enn 2 bokstaver',
            alphaAndHyphenAndApostrophe: 'Brukernavn kan kun bestå av bokstaver og tall'
          }
        },
        email: {
          title: 'E-post',
          placeholder: 'Skriv inn brukerens epost',
          errors: {
            required: 'Brukeren må ha en epost',
            email: 'Eposten må være gyldig'
          }
        },
        passwordNew: {
          title: 'Nytt passord',
          placeholder: 'Skriv inn et passord',
          errors: {
            required: 'Passordet må inneholde minst 8 tegn',
            minLength: 'Passordet må inneholde minst 8 tegn'
          }
        },
        passwordRetype: {
          title: 'Gjenta passord',
          placeholder: 'Gjenta passordet',
          errors: {
            required: 'Passordet må inneholde minst 8 tegn',
            minLength: 'Passordet må inneholde minst 8 tegn',
            sameAs: 'Passordene er ikke like'
          }
        },
        level: {
          title: 'Brukernivå',
          placeholder: 'Velg et brukernivå',
          errors: {
            required: 'Brukeren må ha et brukernivå'
          }
        }
      },
      errorCodes: {
        'auth/email-already-exists': {
          title: 'Eksisterende bruker?',
          message: 'Denne eposten finnes allerede i systemet'
        },
      },
      success: {
        title: 'Brukeren ble oppdatert',
        message: ':user ble oppdatert'
      }
    },
    password: {
      success: {
        title: 'Passordet ble endret',
        message: 'Passordet til :user ble oppdatert'
      }
    },
    delete: {
      title: 'Deaktiver kontoen',
      btn: 'Deaktiver kontoen',
      btnDelete: 'Slett kontoen',
      warning: {
        title: 'Viktig ved sletting/deaktivering av konto',
        message: 'Hvis en bruker deaktiveres, vil ikke brukeren lenger kunne logge inn eller bruke systemet. Hvis en bruker slettes vil informasjonen om brukren permanent slettes fra serveren og det vil ikke være mulig å gjenopprette brukeren senere.' // eslint-disable-line max-len
      },
      checkbox: {
        message: 'Slett kontoen permanent'
      },
      modal: {
        deleted: {
          title: 'Slett kontoen',
          message: 'Er du helt sikker på at du vil slette kontoen til :username permanent? All brukerinformasjon vil bli slettet.', // eslint-disable-line max-len
        },
        disabled: {
          title: 'Deaktiver kontoen',
          message: 'Er du helt sikker på at du vil deaktivere kontoen til :username?'
        }
      },
      toasts: {
        deleted: {
          title: 'Brukeren ble slettet',
          message: 'Kontoen til :username ble slettet'
        },
        disabled: {
          title: 'Brukeren ble deaktivert',
          message: 'Kontoen til :username ble deaktivert'
        }
      }
    },
    reactivate: {
      title: 'Aktiver kontoen',
      btn: 'Aktiver kontoen',
      message: {
        title: 'Informasjon om deaktiverte kontoer',
        message: 'Denne kontoen er deaktivert. Når en konto er deaktivert, kan ikke brukeren logge inn, men all data relatert til kontoen er fortsatt aktiv. Ved aktivering vil brukeren kunne logge inn igjen.' // eslint-disable-line max-len
      }
    }
  }
}
