import LayoutConfigTypes from '@src/core/config/LayoutConfigTypes'
import { randomString } from '../helpers/textUtils'

const config: LayoutConfigTypes = {
  themeName: 'Metronic',
  themeVersion: '8.1.1',
  demo: 'demo1',
  main: {
    type: 'default',
    primaryColor: '#186db6',
    logo: {
      dark: 'media/custom/default-dark.svg',
      light: 'media/custom/default.svg',
    },
  },
  illustrations: {
    set: 'sketchy-1',
  },
  loader: {
    logo: 'media/custom/default-dark.svg',
    display: true,
    type: 'spinner-message',
  },
  scrollTop: {
    display: true,
  },
  header: {
    display: true,
    menuIcon: 'fontawesome',
    width: 'fluid',
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
    height: 55
  },
  toolbar: {
    display: true,
    width: 'fluid',
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  aside: {
    display: true,
    theme: 'light',
    fixed: true,
    menuIcon: 'fontawesome',
    minimized: false,
    minimize: true,
    hoverable: true,
  },
  content: {
    width: 'fixed',
  },
  footer: {
    width: 'fluid',
  },
  uniquekey: {
    uKey: randomString()
  }
}

export default config
