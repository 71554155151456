module.exports = {
  'auth-email-already-exists': {
    title: 'Eksisterende bruker?',
    message: 'Denne eposten finnes allerede i systemet'
  },
  'verification-failed': {
    title: 'Verifisering feilet',
    message: 'Fra serveren: :serverMessage'
  },
  'security-level': {
    title: 'Sikkerhetsfeil oppstod',
    message: 'Du har ikke tilgang til dette brukernivået. Du kan bare sette brukernivåer som er samme som ditt eller lavere' // eslint-disable-line max-len
  },
  'auth-invalid-password': {
    title: 'En feil oppstod',
    message: 'Passordet må bestå av minst 8 tegn' // eslint-disable-line max-len
  },
  'auth-validation': {
    title: 'Sikkerhetsfeil oppstod',
    message: 'Det oppstod en feil på serveren' // eslint-disable-line max-len
  },
  'auth-validation-level': {
    title: 'Sikkerhetsfeil oppstod',
    message: 'Du kan ikke gi brukeren dette nivået. Velg et lavere nivå.'
  },
  'db-doc-not-found': {
    title: 'Fant ikke dokumentet',
    message: 'Det oppstod en feil ved skriving til databasen: Dokumentet eksisterer ikke'
  },
  'db-group-setup-not-found': {
    title: 'Fant ikke oppsettet',
    message: 'Fant ikke oppsettet i gruppen.'
  },
  'misc-unknown': {
    title: 'Ukjent feil oppstod',
    message: 'Det oppstod en ukjent feil'
  }
}
