import objectPath from 'object-path'

export default function useFormValidation (v$: any, submitted: any) {
  const objPath = objectPath.create({ includeInheritedProps: true })

  const validateState = (name: any) => {
     const { $dirty, $error } = objPath.get(v$, `value.${ name }`, { $dirty: false, $error: false })

    if (submitted.value) {
      return $dirty ? !$error : null
    }

    return null
  }

  const validationErrors = (errors: any) => {
    const errorObj = {}

    errors.forEach((err: any) => {
      errorObj[err.$property] = {
        name: err.$property,
        validator: err.$validator
      }
    })

    return errorObj
  }

  return {
    validateState,
    validationErrors
  }
}
