/*
 * Global translations of sentences -> globals
 * In global.js we only translate single words in singular and plural form using transChoice,
 * and in globals.js we translate entire sentences by using "trans"
 */

/* eslint-disable max-len */
module.exports = {
  comingSoon: {
    title: 'Siden kommer snart',
    alert: {
      title: 'Under utvikling',
      desc: 'Denne siden blir utviklet ved behov...'
    }
  },
  loadingPage: {
    loading: 'Laster inn'
  },
  loadingIndicator: {
    loading: 'Henter data',
  },
  toasts: {
    missingTitle: '(mangler tittel)',
    missingMessage: '(mangler melding)'
  },
  alerts: {
    missingTitle: '(mangler tittel)',
    missingMessage: '(mangler melding)'
  },
  inUseBy: 'I bruk av'
}
