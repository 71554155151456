module.exports = {
  index: {
    batchDelete: {
      btn: {
        title: 'Slett enhetene'
      },
      toasts: {
        success: {
          title: 'Enhetene ble slettet',
          message: ':count enheter ble slettet'
        }
      }
    },
    breadcrumb: 'Alle enhetene',
    search: {
      title: 'Sorter etter:',
      placeholder: 'Søk etter enheter...'
    },
    toolbar: {
      export: {
        title: 'Eksporter'
      },
      addCustomer: {
        title: 'Legg til enhet'
      }
    },
    statuses: {
      coming: {
        title: 'Kommer for salg'
      }
    },
    template: {
      noTemplate: 'Ingen mal'
    }
  },
  view: {
    title: 'Enhetsdetaljer',
    image: {
      missing: 'Mangler bilde'
    }
  },
  edit: {
    deleted: {
      title: 'Denne enheten er slettet',
      message: ':authuser slettet denne enheten :datetime'
    },
    delete: {
      title: 'Slett denne enheten',
      message: 'Er du sikker på at du vil slette enheten :itemname permanent?',
      alert: {
        title: 'Viktig informasjon ved sletting av enhet',
        message: 'Når denne enheten slettes, vil den også fjernes fra alle grupper den er koblet til.'
      },
      btn: {
        title: 'Slett enheten',
        processing: 'Sletter enheten'
      },
      toasts: {
        deleted: {
          title: 'Enheten ble slettet',
          message: 'Enheten :name ble slettet'
        },
        disabled: {
          title: 'Enheten ble deaktivert',
          message: 'Enheten :name ble deaktivert'
        }
      }
    },
    image: {
      upload: {
        success: {
          title: 'Bildet ble oppdatert',
          message: 'Bildet til enheten ble oppdatert'
        },
        error: {
          title: 'Det oppstod en feil',
          message: 'En feil oppstod når bildet skulle oppdateres: :error'
        }
      }
    },
    groups: {
      edit: 'Editer',
      delete: 'Fjern fra gruppen'
    },
    copy: {
      btn: {
        title: 'Kopier enheten'
      }
    },
    template: {
      connect: {
        message: 'Koble denne enheten til en mal:'
      },
      create: {
        message: 'Lag en ny mal basert på denne enheten',
      },
      btn: {
        title: 'Lag mal av denne enheten',
        connected: 'Enheten er koblet til en mal...'
      },
      modal: {
        title: 'Lag en mal fra denne enheten',
        message: 'All data fra enheten, bortsett fra gruppene, vil lagres i malen.',
        btn: {
          title: 'Lag mal',
          processing: 'Lager malen, vennligst vent'
        },
        toasts: {
          success: {
            title: 'Malen ble opprettet',
            message: 'Data og filer for den nye malen er klare.'
          }
        }
      }
    },
    restore: {
      title: 'Bruk mal-data'
    }
  },
  forms: {
    create: {
      title: 'Legg til ny enhet',
      fields: {
        name: {
          title: 'Navn',
          placeholder: 'Skriv inn navnet på enheten',
          errors: {
            required: 'Enheten må ha et navn',
            alphaAndHyphenAndApostrophe: 'Navnet kan kun bestå av bokstaver og tall'
          }
        },
        type: {
          title: 'Type',
          placeholder: 'Velg en enhetstype',
          errors: {
            required: 'Enheten må ha en type'
          }
        }
      },
      success: {
        title: 'Enheten ble opprettet',
        message: ':itemname ble opprettet'
      }
    },
    edit: {
      title: 'Enhetsinformasjon',
      btn: {
        title: 'Lagre',
        submitting: 'Lagrer'
      },
      leaflet: {
        btnUpdate: {
          title: 'Endre plassering'
        },
        btnRemove: {
          title: 'Fjern plassering'
        },
        btnLink: {
          title: 'Lag ny kobling'
        },
        alertAdd: {
          title: 'Ingen koblinger',
          message: 'Denne enheten hører ikke til noen grupper'
        }
      },
      details: {
        toolbar: {
          btn: {
            title: 'Legg til felter'
          },
          menu: {
            title: 'Tilgjengelige felter',
            subHeader: 'Velg felter fra listen',
            alert: {
              title: 'Ingen tilgjengelige felter',
              message: 'Ingen felter igjen'
            }
          }
        },
        alert: {
          title: 'Ingen felter',
          message: 'Legg til felter ved å klikke på "+ Legg til felter" knappen'
        }
      },
      copy: {
        modal: {
          title: 'Kopier enheten',
          btn: {
            title: 'Kopier',
            processing: 'Kopierer'
          },
          regularItem: {
            title: 'Kopiering av enheter',
            message: 'Denne enheten er ikke koblet opp mot en mal og alle kopier vil være individuelle.<br><br>Hvis du vil ha muligheten til å endre mange enheter samtidig, må du koble enheten opp mot en mal <b>før</b> du kopierer den.' // eslint-disable-line max-len
          }
        },
        fields: {
          name: {
            title: 'Navn',
            placeholder: 'Skriv inn hovednavnet til den/de nye enheten(e)',
            errors: {
              required: 'Malen må ha et navn',
              alphaAndHyphenAndApostrophe: 'Navnet kan kun bestå av bokstaver, tall og bindestrek',
              minLength: 'Navnet må være lenger enn 1 bokstav'
            }
          },
          suffix: {
            title: 'Endelse (suffiks)',
            placeholder: 'Dynamisk avslutning på navnet',
            description: 'Eksempel: 001, 5001, 101, osv...',
            errors: {

            },
            alert: {
              title: 'Forhåndsvisning av navn',
              titleMultiple: 'Forhåndsvisning av navn til :copies enheter:',
              default: 'Skriv inn navn, eventuelt en endelse og velg antall over for å se resultatet'
            }
          },
          copies: {
            title: 'Antall kopier',
            placeholder: 'Skriv inn antall kopier',
            description: 'Maks 20 hvis filene til enheten også skal kopieres. Uten filer maks 100',
            errors: {
              required: 'Antall må være definert',
              numeric: 'Antall kan kun bestå av tall'
            }
          },
          includeFiles: {
            title: 'Inkluder filer',
            switch: {
              title: 'Alle filene',
              noFilesTitle: 'Enheten har ingen filer'
            }
          }
        }
      },
      fields: {
        status: {
          success: {
            title: 'Enheten ble oppdatert',
            message: ':name sin status ble oppdatert'
          },
          error: {
            title: 'Enheten ble ikke oppdatert',
            message: 'Det oppstod en feil ved oppdatering av enheten: :err'
          }
        },
        type: {
          success: {
            title: 'Enheten ble oppdatert',
            message: ':name sin typedefinisjon ble oppdatert'
          },
          error: {
            title: 'Enheten ble ikke oppdatert',
            message: 'Det oppstod en feil ved oppdatering av enheten: :err'
          }
        },
        templateId: {
          title: 'Mal',
          select: {
            placeholder: 'Velg en mal',
            placeholderActive: 'Fjern malen'
          },
          alert: {
            title: 'Malenhet',
            message: 'Grønne felter er synkronisert med malen'
          },
          success: {
            title: 'Enheten ble oppdatert',
            message: ':name er nå koblet til malen'
          },
          error: {
            title: 'Enheten ble ikke oppdatert',
            message: 'Det oppstod en feil ved tilkobling til malen: :err'
          }
        },
        name: {
          title: 'Navn',
          placeholder: 'Skriv inn et navn',
          description: 'Navnet på enheten',
          errors: {
            required: 'Enheten må ha et navn',
            minLength: 'Navnet må være lenger enn 2 bokstaver',
            alphaAndHyphenAndApostrophe: 'Navnet kan kun bestå av bokstaver og tall'
          }
        },
        description: {
          title: 'Beskrivelse',
          placeholder: 'Skriv en beskrivelse av enheten',
          description: 'Beskrivelse av enheten',
          errors: {
            minLength: 'Beskrivelsen må være legner enn 2 tegn'
          }
        },
        dynamicFields: {
          price: {
            title: 'Pris',
            placeholder: 'Skriv inn prisen (kun tall)',
            description: 'Prisen på enheten',
            errors: {
              required: 'Prisen mangler',
              numeric: 'Prisen må bestå av kun tall'
            }
          },
          bedrooms: {
            title: 'Soverom',
            placeholder: 'Skriv inn antall soverom',
            description: 'Antall soverom',
            errors: {
              required: 'Antall soverom mangler',
              numeric: 'Antall soverom må bestå av kun tall'
            }
          },
          usableFloorSpace: {
            title: 'Bruksareal (BRA)',
            placeholder: 'Skriv inn bruksarealet i kvm',
            description: 'Bruksareal i kvm',
            errors: {
              required: 'Bruksarealet mangler',
              numeric: 'Bruksarealet må bestå av kun tall i kvm'
            }
          },
          primaryFloorSpace: {
            title: 'Primærrom (P-ROM)',
            placeholder: 'Skriv inn primærrommet i kvm',
            description: 'Primærrom i kvm',
            errors: {
              required: 'Primærrommet mangler',
              numeric: 'Primærrommet må bestå av kun tall (i kvm)'
            }
          },
          floor: {
            title: 'Etasje',
            placeholder: 'Skriv inn i hvilken etasje enheten ligger',
            description: 'Hvilken etasje enheten ligger i',
            errors: {
              required: 'Etasjen mangler',
              numeric: 'Etasjen må bestå av kun tall'
            }
          },
          floors: {
            title: 'Etasjer',
            placeholder: 'Skriv inn hvor mange etasjer enheten har',
            description: 'Hvor mange etasjer består enheten av',
            errors: {
              required: 'Antall etasjer mangler',
              numeric: 'Antall etasjer må bestå av kun tall'
            }
          },
          balcony: {
            title: 'Balkong',
            placeholder: 'Skriv inn hvor stor balkongen er (i kvm)',
            description: 'Hvor stor balkongen er i kvm',
            errors: {
              required: 'Størrelsen på balkongen mangler',
              numeric: 'Størrelsen på balkongen må bestå av kun tall (i kvm)'
            }
          },
          priceExtLink: {
            title: 'Ekstern kjøpslink',
            placeholder: 'https://link-til-kjøpssiden.no',
            description: 'Link til en ekstern kjøpsside for enheten',
            errors: {
              required: 'URL til kjøpssiden må skrives inn',
              minLength: 'URLen må være lenger enn 2 tegn'
            }
          }
        }
      },
      success: {
        title: 'Enheten ble oppdatert',
        message: ':name ble oppdatert'
      },
      deleteField: {
        delete: {
          title: 'Slett feltet',
          titleTemplate: 'Ignorer feltet'
        },
        restore: {
          title: 'Bruk maldata'
        }
      }
    },
    deleteField: {
      modal: {
        title: 'Slett feltet fra enheten',
        message: 'Er du sikker på at du vil slette feltet :fieldname fra :name',
        btn: {
          title: 'Slett feltet'
        }
      },
      toasts: {
        success: {
          title: 'Feltet ble slettet',
          message: 'Feltet ble slettet fra :name'
        }
      }
    },
    ignoreField: {
      modal: {
        title: 'Ignorer feltet fra malen',
        message: 'Er du sikker på at du vil ignorere feltet :fieldname fra malen?',
        btn: {
          title: 'Ignorer feltet'
        }
      },
      toasts: {
        success: {
          title: 'Feltet er ignorert',
          message: 'Feltet fra malen :name vil ikke vises i denne enheten.'
        }
      }
    },
    removeGroup: {
      modal: {
        title: 'Slett gruppe fra enheten',
        message: 'Er du sikker på at du vil fjerne denne enheten fra gruppen :groupname',
        btn: {
          title: 'Fjern gruppen'
        }
      },
      toasts: {
        removed: {
          title: 'Gruppen ble fjernet fra enheten',
          message: 'Denne enheten ble fjernet fra gruppen :groupname'
        }
      }
    },
    reactivate: {
      title: 'Aktiver enheten',
      btn: 'Aktiver enheten',
      message: {
        title: 'Informasjon om deaktiverte enheter',
        message: 'Denne enheten er deaktivert. Når en enhet er deaktivert, vil den ikke vises i boligvelgeren.'
      }
    }
  },
  media: {
    breadcrumb: 'Medier',
    plans: {
      title: 'Plantegninger',
      noFiles: {
        title: 'Ingen plantegninger ble funnet',
        message: 'Dra plantegninger hit eller klikk her for å laste opp plantegninger'
      },
      dropzone: {
        default: 'Dra og slipp plantegninger her, eller klikk for å velge plantegninger manuelt',
        defaultMessage: 'Last opp max 10 plantegninger samtidig',
        dragActive: 'Slipp plantegningene her',
      },
      upload: {
        fileExists: {
          title: 'Plantegningen finnes allerede',
          message: 'Plantegningen :filename finnes allerede i denne enheten'
        }
      },
      delete: {
        title: 'Slett plantegningen',
        toasts: {
          success: {
            title: 'Plantegningen ble slettet',
            message: 'Plantegningen :filename ble slettet'
          },
          error: {
            title: 'Plantegningen ble ikke slettet',
            message: 'Det oppstod en feil ved sletting av plantegningen: :err'
          }
        }
      }
    },
    images: {
      title: 'Bilder',
      noFiles: {
        title: 'Ingen bilder ble funnet',
        message: 'Dra bilder hit eller klikk her for å laste opp bilder'
      },
      dropzone: {
        default: 'Dra og slipp bilder her, eller klikk for å velge bilder manuelt',
        defaultMessage: 'Last opp max 10 bilder samtidig',
        dragActive: 'Slipp bildene her',
      },
      upload: {
        fileExists: {
          title: 'Bildet finnes allerede',
          message: 'Bildet :filename finnes allerede i denne enheten'
        }
      },
      delete: {
        title: 'Slett bildet',
        toasts: {
          success: {
            title: 'Bildet ble slettet',
            message: 'Bildet :filename ble slettet'
          },
          error: {
            title: 'Bildet ble ikke slettet',
            message: 'Det oppstod en feil ved sletting av bildet: :err'
          }
        }
      }
    },
    templateFiles: {
      title: 'Filer og dokumenter fra malen'
    },
    files: {
      title: 'Filer og dokumenter',
      noFiles: {
        title: 'Ingen filer funnet',
        message: 'Dra filer hit eller klikk her for å laste opp filer'
      },
      dropzone: {
        default: 'Dra og slipp filer her, eller klikk for å velge filer manuelt',
        defaultMessage: 'Last opp max 10 filer samtidig',
        dragActive: 'Slipp filene her',
      },
      upload: {
        fileExists: {
          title: 'Filen finnes allerede',
          message: 'Filen :filename finnes allerede i denne enheten'
        }
      },
      delete: {
        title: 'Slett filen',
        toasts: {
          success: {
            title: 'Filen ble slettet',
            message: 'Filen :filename ble slettet'
          },
          error: {
            title: 'Filen ble ikke slettet',
            message: 'Det oppstod en feil ved sletting av filen: :err'
          }
        }
      }
    }
  }
}
