/* eslint-disable max-len */
module.exports = {
  index: {
    title: 'Alle hendelser'
  },
  users: {
    login: {
      title: 'Bruker login',
      desc: ':username logget inn',
      unknown: '(ukjent bruker)',
      btn: {
        label: 'Se brukeren'
      }
    },
    logout: {
      title: 'Bruker logout',
      desc: ':username logget seg ut',
      unknown: '(ukjent bruker)',
      btn: {
        label: 'Se brukeren'
      }
    },
    update: {
      title: 'Oppdatering av bruker',
      desc: ':username ble oppdatert av :authuser',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se brukeren'
      }
    },
    activate: {
      title: 'Aktivering av bruker',
      desc: ':username ble aktivert av :authuser',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se brukeren'
      }
    },
    delete: {
      title: 'Sletting av bruker',
      desc: ':username ble slettet av :authuser',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se brukeren'
      }
    },
    disabled: {
      title: 'Deaktivering av konto',
      desc: 'Kontoen til :username ble deaktivert av :authuser',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se brukeren'
      }
    },
    create: {
      title: 'En bruker ble opprettet',
      desc: ':username ble opprettet av :authuser',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se brukeren'
      }
    }
  },
  groupItems: {
    update: {
      title: 'Oppdatering av gruppe-element',
      desc: ':authuser oppdaterte elementet :elid i gruppen :groupname',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se elementet i gruppen'
      }
    },
    delete: {
      title: 'Sletting av gruppe-element',
      desc: ':authuser slettet elementet :elid fra :groupname',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppens elementer'
      }
    },
    create: {
      title: 'Gruppe-element ble opprettet',
      desc: ':authuser opprettet et nytt element i gruppen :groupname',
      unknown: '(mangler data)',
      btn: {
        label: 'Se elementet i gruppen'
      }
    }
  },
  groups: {
    disabled: {
      title: 'Deaktivering av gruppe',
      desc: 'Gruppen :groupname ble deaktivert av :authuser',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se brukeren'
      }
    },
    update: {
      title: 'Oppdatering av gruppe',
      desc: ':authuser oppdaterte gruppen :groupname',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppedetaljene'
      }
    },
    updateImage: {
      title: 'Oppdatering av gruppebilde',
      desc: ':authuser oppdaterte bildet til gruppen :groupname',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppen'
      }
    },
    delete: {
      title: 'Sletting av gruppe',
      desc: ':authuser slettet gruppen :groupname',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppen'
      }
    },
    create: {
      title: 'En gruppe ble opprettet',
      desc: ':authuser opprettet en ny gruppe med navn :groupname',
      unknown: '(mangler data)',
      btn: {
        label: 'Se elementet i gruppen'
      }
    },
    updateSetupImage: {
      title: 'Oppdatering av gruppeoppsettbilde',
      desc: ':authuser oppdaterte bildet i gruppeoppsettet: :groupsetupname'
    }
  },
  groupSetups: {
    create: {
      title: 'Et nytt gruppeoppsett ble opprettet',
      desc: ':authuser opprettet gruppeoppsettet :groupname'
    }
  },
  items: {
    batchDelete: {
      title: 'Sletting av flere enheter',
      desc: ':authuser slettet :count enheter: :deleteditems (:faileditems feilet)',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppen'
      }
    },
    create: {
      title: 'En enhet ble opprettet',
      desc: ':authuser opprettet en ny enhet med navn :itemname',
      unknown: '(mangler data)',
      btn: {
        label: 'Se elementet i gruppen'
      }
    },
    copy: {
      title: 'Enhet ble kopiert',
      desc: ':authuser kopierte enheten :sourcename til :count nye enheter: :copieditems',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppen'
      }
    },
    delete: {
      title: 'En enhet ble slettet',
      desc: ':authuser slettet enheten ":itemname"',
      unknown: '(mangler data)',
      btn: {
        label: 'Se elementet i gruppen'
      }
    },
    fieldRemoved: {
      title: 'Et felt ble fjernet fra en enhet',
      desc: ':authuser fjernet feltet ":fields" fra :itemname',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppedetaljene'
      }
    },
    templateFromItem: {
      title: 'En mal ble opprettet fra en enhet',
      desc: ':authuser opprettet malen ":templatename" basert på enheten :itemname',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppedetaljene'
      }
    },
    update: {
      title: 'Enhet ble oppdatert',
      desc: ':authuser oppdaterte enheten :itemname [:fields]',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppedetaljene'
      }
    },
    uploadFiles: {
      title: 'Filer ble lastet opp til enhet',
      desc: ':authuser lastet opp filer til enheten :itemname [:files]',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppedetaljene'
      }
    }
  },
  itemTemplates: {
    create: {
      title: 'En mal ble opprettet',
      desc: ':authuser opprettet en ny mal med navn :templatename',
      unknown: '(mangler data)',
      btn: {
        label: 'Se elementet i gruppen'
      }
    },
    delete: {
      title: 'En mal ble slettet',
      desc: ':authuser slettet malen ":itemname"',
      unknown: '(mangler data)',
      btn: {
        label: 'Se elementet i gruppen'
      }
    },
    fieldRemoved: {
      title: 'Et felt ble fjernet fra en mal',
      desc: ':authuser fjernet feltet ":fields" fra malen :itemname',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppedetaljene'
      }
    },
    update: {
      title: 'Oppdatering av mal',
      desc: ':authuser oppdaterte malen :templatename [:fields]',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppedetaljene'
      }
    },
    uploadFiles: {
      title: 'Filer ble lastet opp til en mal',
      desc: ':authuser lastet opp filer til malen :templatename [:files]',
      unknown: '(mangler navnet i DB)',
      btn: {
        label: 'Se gruppedetaljene'
      }
    }
  },
  settings: {
    update: {
      title: 'Innstillinger ble oppdatert',
      desc: ':authuser oppdaterte innstillingene: :settings',
      unknown: '(mangler data i DB)',
      btn: {
        label: 'Se innstillingene'
      }
    }
  },
  api: {
    sync: {
      create: {
        title: 'Manuell synkronisering',
        desc: ':authuser kjørte en manuell Wordpress synkronisering: :syncdata',
        unknown: '(mangler data i DB)'
      }
    }
  }
}
