import { Mutation, Module, VuexModule } from 'vuex-module-decorators'
import { ToastMutations } from '@src/store/enums/ToastEnums'

interface Toast {
  id: string
  title: string
  message: string
}

interface StoreInfo {
  toast: object
}

@Module
export default class ToastsModule extends VuexModule implements StoreInfo {
  toast = {} as Toast

  get getToast (): Toast {
    return this.toast
  }

  @Mutation
  [ToastMutations.SET_TOAST] (payload) {
    this.toast = payload
  }

  @Mutation
  [ToastMutations.DELETE_TOAST] () {
    this.toast = {} as Toast
  }
}
