// eslint-disable-next-line no-shadow
enum ToolbarGetters {
  GET_ACTIVE_TOOLBAR = 'getActiveToolbar'
}

// eslint-disable-next-line no-shadow
enum ToolbarActions {
  SET_ACTIVE_TOOLBAR = 'setActiveToolbarAction'
}

// eslint-disable-next-line no-shadow
enum ToolbarMutations {
  SET_ACTIVE_TOOLBAR = 'setActiveToolbarMutation'
}

export { ToolbarGetters, ToolbarActions, ToolbarMutations }
