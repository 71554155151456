// SettingsModule.ts
// Direct copy from theme -> mods marked -> eslint errors ignored to keep the structure as in theme
import objectPath from 'object-path'
import merge from 'deepmerge'
import settingsConfig from '@src/core/config/DefaultSettingsConfig' // Modified file
import { SettingsMutations } from '@src/store/enums/SettingsEnums'
import { Mutation, Module, VuexModule } from 'vuex-module-decorators'
import SettingConfigTypes from '@src/core/config/SettingConfigTypes'

interface StoreInfo {
  config: SettingConfigTypes
  initial: SettingConfigTypes
}

@Module
export default class SettingsModule extends VuexModule implements StoreInfo {
  config: SettingConfigTypes = settingsConfig
  initial: SettingConfigTypes = settingsConfig

  /**
   * Get config from settings config
   * @returns {function(path, defaultValue): *}
   */
  get settingsConfig () {
    // eslint-disable-next-line arrow-body-style
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue)
    }
  }

  @Mutation
  [SettingsMutations.SET_SETTINGS_CONFIG] (payload: any): void {
    this.config = { ...this.initial, ...payload }
  }

  @Mutation
  [SettingsMutations.RESET_SETTINGS_CONFIG] () {
    // eslint-disable-next-line prefer-object-spread
    this.config = Object.assign({}, this.initial)
  }

  @Mutation
  [SettingsMutations.SET_SETTINGS_VALUE] (payload: any): void {
    this.config = merge(this.config, payload)
  }

  @Mutation
  [SettingsMutations.OVERRIDE_SETTINGS_CONFIG] (): void {
    // eslint-disable-next-line no-multi-assign, prefer-object-spread
    this.config = this.initial = Object.assign(
      {},
      this.initial,
      JSON.parse(window.localStorage.getItem('config') || '{}')
    )
  }
}
