/* eslint-disable max-len */
module.exports = {
  breadcrumb: 'API',
  toolbar: {
    title: 'Synkroniser data'
  },
  sync: {
    breadcrumb: 'Synkronisering',
    about: {
      title: 'Om synkroniseringen',
      description: 'Denne boligvelgeren benytter prospekter i Wordpress for å vise informasjon om hver leilighet. Synkroniseringen oppdaterer data i Wordpress med data fra boligvelgeren.<br><br>Boligvelgeren kjører en automatisk synkronisering en gang i timen. Må Wordpress oppdateres med en gang, kan det kjøres en manuell synkronisering.',
      info: {
        title: 'Automatisk oppdatering',
        description: 'Synkronisering mellom boligvelger og Wordpress kjøres automatisk en gang i timen.'
      }
    },
    errors: {
      missing: {
        title: 'Mangler API endpoint',
        message: 'API endpointet må defineres under innstillinger'
      },
      endpoint: {
        title: 'API endpoint feil',
        message: 'Wordpress API URL\'en svarer med feil, vennligst sjekk at URL\'en er riktig.'
      }
    },
    lastSync: {
      title: 'Siste synkronisering',
      ready: 'Manuell synkronisering kan kjøres hvert 3 minutt',
      toolbar: {
        btn: {
          title: 'Start synkronisering'
        }
      },
      status: {
        started: 'Startet',
        ended: 'Fullført'
      },
      progress: {
        title: 'Status'
      }
    },
    logs: {
      title: 'Synkroniseringslogg',
      unknownUser: '(ukjent brukernavn)',
      unknownDate: '(ukjent dato)'
    }
  }
}
