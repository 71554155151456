module.exports = {
  index: {
    breadcrumb: 'Alle malene',
    search: {
      title: 'Sorter etter:',
      placeholder: 'Søk etter maler...'
    },
    toolbar: {
      export: {
        title: 'Eksporter'
      },
      addCustomer: {
        title: 'Legg til mal'
      }
    },
    statuses: {
      coming: {
        title: 'Kommer for salg'
      }
    }
  },
  view: {
    title: 'Enhetsdetaljer',
    image: {
      missing: 'Mangler bilde'
    }
  },
  edit: {
    headings: {
      template: {
        title: 'Om malen',
        message: 'Disse feltene vil ikke påvirke enheter som er knyttet til malen'
      },
      item: {
        title: 'Maldata',
        message: 'Disse feltene påvirker alle enheter som er knyttet til malen'
      },
      delete: {
        table: {
          name: 'Malen brukes av:'
        }
      }
    },
    deleted: {
      title: 'Denne malen er slettet',
      message: ':authuser slettet denne malen :datetime'
    },
    delete: {
      title: 'Slett malen',
      message: 'Er du sikker på at du vil slette malen :itemname permanent?',
      alert: {
        ok: {
          title: 'Viktig informasjon ved sletting av mal',
          message: 'Når denne malen slettes, vil den også fjernes fra alle enheter den er koblet til.'
        },
        inUse: {
          title: 'Denne malen er i bruk',
          message: 'For å kunne slette denne malen så kan den ikke være i bruk av noen enheter'
        }
      },
      btn: {
        title: 'Slett malen',
        processing: 'Sletter malen'
      },
      toasts: {
        deleted: {
          title: 'Templatet ble slettet',
          message: 'Templatet :name ble slettet'
        },
        disabled: {
          title: 'Templatet ble deaktivert',
          message: 'Templatet :name ble deaktivert'
        }
      }
    },
    image: {
      upload: {
        success: {
          title: 'Bildet ble oppdatert',
          message: 'Bildet til malen ble oppdatert'
        },
        error: {
          title: 'Det oppstod en feil',
          message: 'En feil oppstod når bildet skulle oppdateres: :error'
        }
      }
    },
    groups: {
      edit: 'Editer',
      delete: 'Fjern fra gruppen'
    },
    template: {
      connect: {
        message: 'Koble denne malen til en mal:'
      },
      create: {
        message: 'Lag et nytt mal basert på denne malen',
      },
      btn: {
        title: 'Lag mal av denne malen'
      },
      modal: {
        title: 'Lag en mal fra denne malen',
        message: 'All data fra malen, bortsett fra gruppene, vil lagres i malen.',
        btn: {
          title: 'Lag mal',
          processing: 'Lager malen'
        }
      }
    }
  },
  forms: {
    create: {
      title: 'Legg til en ny mal',
      fields: {
        name: {
          title: 'Navn',
          placeholder: 'Skriv inn navnet på malen',
          errors: {
            required: 'Malen må ha et navn',
            alphaAndHyphenAndApostrophe: 'Navnet kan kun bestå av bokstaver, tall og bindestrek',
            minLength: 'Navnet må være lenger enn 2 bokstaver'
          }
        },
        type: {
          title: 'Type',
          placeholder: 'Velg en type',
          errors: {
            required: 'Malen må ha en type'
          }
        }
      },
      success: {
        title: 'Malen ble opprettet',
        message: 'Malen :templatename av typen :templatetype ble opprettet'
      }
    },
    edit: {
      title: 'Enhetsinformasjon',
      btn: {
        title: 'Lagre',
        submitting: 'Lagrer'
      },
      leaflet: {
        btnUpdate: {
          title: 'Endre plassering'
        },
        btnRemove: {
          title: 'Fjern plassering'
        },
        btnLink: {
          title: 'Lag ny kobling'
        },
        alertAdd: {
          title: 'Ingen koblinger',
          message: 'Denne malen hører ikke til noen grupper'
        }
      },
      details: {
        toolbar: {
          btn: {
            title: 'Legg til felter'
          },
          menu: {
            title: 'Tilgjengelige felter',
            subHeader: 'Velg felter fra listen',
            alert: {
              title: 'Ingen tilgjengelige felter',
              message: 'Ingen felter igjen'
            }
          }
        },
        alert: {
          title: 'Ingen felter',
          message: 'Legg til felter ved å klikke på "+ Legg til felter" knappen'
        }
      },
      fields: {
        status: {
          success: {
            title: 'Templatet ble oppdatert',
            message: ':name sin status ble oppdatert'
          },
          error: {
            title: 'Templatet ble ikke oppdatert',
            message: 'Det oppstod en feil ved oppdatering av malen: :err'
          }
        },
        type: {
          success: {
            title: 'Templatet ble oppdatert',
            message: ':name sin typedefinisjon ble oppdatert'
          },
          error: {
            title: 'Templatet ble ikke oppdatert',
            message: 'Det oppstod en feil ved oppdatering av malen: :err'
          }
        },
        template: {
          title: 'Mal',
          select: {
            placeholder: 'Velg en mal'
          },
          alert: {
            title: 'Templattemplat',
            message: 'Denne malen er en mal.'
          }
        },
        name: {
          title: 'Navn',
          placeholder: 'Skriv inn et navn',
          description: 'Navnet på malen',
          errors: {
            required: 'Templatet må ha et navn',
            minLength: 'Navnet må være lenger enn 2 bokstaver',
            alphaAndHyphenAndApostrophe: 'Navnet kan kun bestå av bokstaver og tall'
          }
        },
        description: {
          title: 'Beskrivelse',
          placeholder: 'Skriv en beskrivelse av malen',
          description: 'Beskrivelse av malen',
          errors: {
            minLength: 'Beskrivelsen må være legner enn 2 tegn'
          }
        },
        price: {
          title: 'Pris',
          placeholder: 'Skriv inn prisen (kun tall)',
          description: 'Prisen på malen',
          errors: {
            required: 'Prisen mangler',
            numeric: 'Prisen må bestå av kun tall'
          }
        },
        bedrooms: {
          title: 'Soverom',
          placeholder: 'Skriv inn antall soverom',
          description: 'Antall soverom',
          errors: {
            required: 'Antall soverom mangler',
            numeric: 'Antall soverom må bestå av kun tall'
          }
        },
        usableFloorSpace: {
          title: 'Bruksareal (BRA)',
          placeholder: 'Skriv inn bruksarealet i kvm',
          description: 'Bruksareal i kvm',
          errors: {
            required: 'Bruksarealet mangler',
            numeric: 'Bruksarealet må bestå av kun tall i kvm'
          }
        },
        primaryFloorSpace: {
          title: 'Primærrom (P-ROM)',
          placeholder: 'Skriv inn primærrommet i kvm',
          description: 'Primærrom i kvm',
          errors: {
            required: 'Primærrommet mangler',
            numeric: 'Primærrommet må bestå av kun tall (i kvm)'
          }
        },
        floor: {
          title: 'Etasje',
          placeholder: 'Skriv inn i hvilken etasje malen ligger',
          description: 'Hvilken etasje malen ligger i',
          errors: {
            required: 'Etasjen mangler',
            numeric: 'Etasjen må bestå av kun tall'
          }
        },
        floors: {
          title: 'Etasjer',
          placeholder: 'Skriv inn hvor mange etasjer malen har',
          description: 'Hvor mange etasjer består malen av',
          errors: {
            required: 'Antall etasjer mangler',
            numeric: 'Antall etasjer må bestå av kun tall'
          }
        },
        balcony: {
          title: 'Balkong',
          placeholder: 'Skriv inn hvor stor balkongen er (i kvm)',
          description: 'Hvor stor balkongen er i kvm',
          errors: {
            required: 'Størrelsen på balkongen mangler',
            numeric: 'Størrelsen på balkongen må bestå av kun tall (i kvm)'
          }
        }
      },
      success: {
        title: 'Templatet ble oppdatert',
        message: ':name ble oppdatert'
      },
      deleteField: {
        title: 'Slett feltet'
      }
    },
    deleteField: {
      modal: {
        title: 'Slett feltet fra malen',
        message: 'Er du sikker på at du vil slette feltet :fieldname fra :name',
        btn: {
          title: 'Slett feltet'
        }
      },
      toasts: {
        success: {
          title: 'Feltet ble slettet',
          message: 'Feltet :fieldname ble slettet fra :name'
        }
      }
    },
    reactivate: {
      title: 'Aktiver malen',
      btn: 'Aktiver malen',
      message: {
        title: 'Informasjon om deaktiverte maler',
        message: 'Denne malen er deaktivert. Når en mal er deaktivert, vil den ikke vises i boligvelgeren.'
      }
    }
  },
  media: {
    breadcrumb: 'Medier',
    plans: {
      title: 'Plantegninger',
      noFiles: {
        title: 'Ingen plantegninger ble funnet',
        message: 'Dra plantegninger hit eller klikk her for å laste opp plantegninger'
      },
      dropzone: {
        default: 'Dra og slipp plantegninger her, eller klikk for å velge plantegninger manuelt',
        defaultMessage: 'Last opp max 10 plantegninger samtidig',
        dragActive: 'Slipp plantegningene her',
      },
      upload: {
        fileExists: {
          title: 'Plantegningen finnes allerede',
          message: 'Plantegningen :filename finnes allerede i denne malen'
        }
      },
      delete: {
        title: 'Slett plantegningen',
        toasts: {
          success: {
            title: 'Plantegningen ble slettet',
            message: 'Plantegningen :filename ble slettet'
          },
          error: {
            title: 'Plantegningen ble ikke slettet',
            message: 'Det oppstod en feil ved sletting av plantegningen: :err'
          }
        }
      }
    },
    images: {
      title: 'Bilder',
      noFiles: {
        title: 'Ingen bilder ble funnet',
        message: 'Dra bilder hit eller klikk her for å laste opp bilder'
      },
      dropzone: {
        default: 'Dra og slipp bilder her, eller klikk for å velge bilder manuelt',
        defaultMessage: 'Last opp max 10 bilder samtidig',
        dragActive: 'Slipp bildene her',
      },
      upload: {
        fileExists: {
          title: 'Bildet finnes allerede',
          message: 'Bildet :filename finnes allerede i denne malen'
        }
      },
      delete: {
        title: 'Slett bildet',
        toasts: {
          success: {
            title: 'Bildet ble slettet',
            message: 'Bildet :filename ble slettet'
          },
          error: {
            title: 'Bildet ble ikke slettet',
            message: 'Det oppstod en feil ved sletting av bildet: :err'
          }
        }
      }
    },
    files: {
      title: 'Filer og dokumenter',
      noFiles: {
        title: 'Ingen filer funnet',
        message: 'Dra filer hit eller klikk her for å laste opp filer'
      },
      dropzone: {
        default: 'Dra og slipp filer her, eller klikk for å velge filer manuelt',
        defaultMessage: 'Last opp max 10 filer samtidig',
        dragActive: 'Slipp filene her',
      },
      upload: {
        fileExists: {
          title: 'Filen finnes allerede',
          message: 'Filen :filename finnes allerede i denne malen'
        }
      },
      delete: {
        title: 'Slett filen',
        toasts: {
          success: {
            title: 'Filen ble slettet',
            message: 'Filen :filename ble slettet'
          },
          error: {
            title: 'Filen ble ikke slettet',
            message: 'Det oppstod en feil ved sletting av filen: :err'
          }
        }
      }
    }
  }
}
