/* eslint-disable max-len */
module.exports = {
  login: {
    login: 'Logg inn',
    loggingIn: 'Logger inn...',
    noAccount: 'Har du ikke en konto?',
    register: 'Registrer deg',
    emailAndPassword: 'Skriv inn din epost og ditt passord',
    yourEmail: 'Din epost',
    yourPassword: 'Ditt passord',
    forgotPassword: 'Glemt passord?',
    messages: {
      validEmailRequired: 'Skriv inn en gyldig epostaddresse',
      passwordRequired: 'Skriv inn passordet ditt (minst 8 tegn)',
      wrongUsernameOrPassword: 'Feil brukernavn eller passord',
      tooManyRequests: 'For mange forsøk, vennligst prøv igjen senere',
      autologout: 'Du har blitt automatisk logget ut. Logg inn på nytt.',
      userDisabled: 'Brukeren har blitt deaktivert',
      providerDisabled: 'Denne loginfunksjonen er deaktivert',
      noConfigFound: 'Denne loginmetoden er ikke støttet',
      missingUserInDB: 'Mangler brukerdata i databasen',
      unknownError: 'En uventet feil har oppstått',
      networkError: 'Kan ikke koble til serveren',
      apiUnreachable: 'Kan ikke koble til API-serveren',
      noToken: 'Mangler API-token'
    }
  }
}
