// eslint-disable-next-line no-shadow
enum AuthGetters {
  GET_ALL = 'getAll',
  GET_AUTH_STATE = 'getAuthState'
}

// eslint-disable-next-line no-shadow
enum AuthActions {
  GET_AUTH_USERDATA = 'getAuthData',
  LOGIN_USER = 'loginUser',
  LOGOUT_FROM_FB = 'logoutFromFb'
}

// eslint-disable-next-line no-shadow
enum AuthMutations {
  SET_DATA = 'setData',
  SET_AUTH_READY = 'setAuthReady',
  SET_NEW_TOKEN = 'setNewToken'
}

export { AuthGetters, AuthActions, AuthMutations }
