/*
 * Modified AuthModule.ts store from the Metronic Theme
 * Keeping this no-namespaced to optimize theme compability (!important)
 */

import router from '@src/router' // eslint-disable-line import/no-cycle
import objectPath from 'object-path'
import fb from '@src/core/config/firebase'
import apiConfig from '@src/core/config/apiConfig'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'
import { useApiAsync } from '@src/use/apiAsync' // eslint-disable-line import/no-cycle
import { Actions, Mutations } from '../enums/StoreEnums' // Theme default enums
import { AuthGetters, AuthActions, AuthMutations } from '../enums/AuthEnums'
import { SettingsMutations } from '../enums/SettingsEnums'

const objPath = objectPath.create({ includeInheritedProps: true })

const checkAuthStatus = () => new Promise((resolve, reject) => {
  const auth = fb.auth.getAuth()

  try {
    fb.auth.onAuthStateChanged(auth, (user) => resolve(user))
  } catch {
    reject(new Error('api failed'))
  }
})

@Module
export default class AuthModule extends VuexModule {
  errors = {}
  user: any = {} // Firebase user data (Firebase auth system) -> uses {} because of theme compability
  data = null // Firestore DB (users) data
  isAuthenticated = false
  isReady = false

  // Getters
  get [AuthGetters.GET_ALL] () {
    return Object.keys(this.user).length > 0 && this.data !== null
      ? {
        user: this.user,
        data: this.data
      }
      : null
  }

  get [AuthGetters.GET_AUTH_STATE] () {
    return this.isReady
  }

  // Actions

  // Actions.VERIFY_AUTH
  // Triggered on beforeEach router update
  @Action
  async [Actions.VERIFY_AUTH] () {
    console.log('[VUEX :: Auth] VERIFY_AUTH')
    // Check for valid Firebase user -> else send to login screen
    if (Object.keys(this.user).length === 0) {
      const firebaseUser = await checkAuthStatus()
      if (firebaseUser) {
        this.context.commit(Mutations.SET_AUTH, firebaseUser) // Firebase Auth system userdata
      } else {
        return router.push('/login')
      }
    }

    // Check for valid Firestore user -> else send to login screen
    if (!this.data) {
      const authData = await this.context.dispatch(AuthActions.GET_AUTH_USERDATA, this.user.accessToken)
      if (!authData || authData.errors !== undefined) {
        await this.context.dispatch(AuthActions.LOGOUT_FROM_FB)
        return router.push('/login')
      }

      this.context.commit(AuthMutations.SET_DATA, authData)

      // Store usersettings
      const userSettings = objPath.get(authData, 'settings', {})
      this.context.commit(SettingsMutations.SET_SETTINGS_CONFIG, userSettings, { root: true })
    }

    // Both Firebase and Firestore data valid? Auth OK
    if (Object.keys(this.user).length > 0 && this.data) {
      this.context.commit(AuthMutations.SET_AUTH_READY)
    }

    return true
  }

  // rawError is required to be ble to throw errors
  @Action({ rawError: true })
  async [Actions.LOGIN] (data: any) {
    console.log('[VUEX :: Auth] LOGIN')

    if (data.user !== undefined) {
      const authUser = data.user

      // @ts-expect-error: Fix typehinting
      const mode = objPath.get(data, 'mode', 'auto') === 'manually' ? AuthActions.LOGIN_USER : AuthActions.GET_AUTH_USERDATA // eslint-disable-line

      // Firestore DB userdata (users)
      const authData = await this.context.dispatch(mode, data.user.accessToken)
        .then((res) => res)
        .catch((err) => ({ error: err }))

      // If the API is unreachable
      if (!authData) {
        throw {
          code: 'custom/api-unreachable',
          servermsg: ''
        }
      }

      // If errors
      if (authData !== undefined && authData.error !== undefined) {
        await this.context.dispatch(AuthActions.LOGOUT_FROM_FB) // Logout from Firebase if login fails
        // @ts-expect-error: Fix typehinting
        if (mode === 'auto') {
          // From anywhere in the app
          this.context.commit(Mutations.SET_ERROR, { code: authData.error.code }, { root: true })
          router.push('/login')
        } else {
          // From the login-screen
          throw {
            code: authData.error.code,
            servermsg: authData.error.message
          }
        }
      }

      // Store userinfo
      this.context.commit(AuthMutations.SET_DATA, authData) // Firestore DB userdata (users)
      this.context.commit(Mutations.SET_AUTH, authUser) // Firebase Auth system userdata

      // Store usersettings
      const userSettings = objPath.get(authData, 'settings', {})
      this.context.commit(SettingsMutations.SET_SETTINGS_CONFIG, userSettings, { root: true })

      // Login done!
      this.context.commit(AuthMutations.SET_AUTH_READY)

      return {
        auth: authUser,
        data: authData
      }
    }

    console.error('[VUEX :: Auth] Login: ERROR, user is required')
    return false
  }

  @Action
  [Actions.LOGOUT] () {
    console.log('[VUEX :: Auth] Logout')
    const { useApi } = useApiAsync()

    // Important do this async
    if (objPath.has(this, 'user.accessToken')) {
      useApi({ api: apiConfig.backend.users.logout, data: {} }).then((res) => {
        console.log('[LOGOUT] Result: ', res)
      })
    }

    this.context.commit(Mutations.PURGE_AUTH)
  }

  @Action
  [AuthActions.LOGOUT_FROM_FB] () {
    console.log('[VUEX :: Auth] Logout from FB')
    return new Promise((resolve, reject) => {
      fb.auth.signOut(fb.auth.getAuth()).then(() => {
        resolve(this.context.dispatch(Actions.LOGOUT))
      }).catch((err: any) => {
        reject(err)
      })
    })
  }

  /**
   * LOGIN_USER
   * Basically the same as "GET_AUTH_USERDATA", except the api-function has some
   * additional login-features (logging)
   */
   @Action({ rawError: true })
  async [AuthActions.LOGIN_USER] (apiToken = '') { // eslint-disable-line class-methods-use-this
    console.log('[VUEX :: Auth] LOGIN_USER')
    const { useApi } = useApiAsync({ apiToken })
    // eslint-disable-next-line no-restricted-syntax, no-labels, no-unused-labels
    return await useApi({ api: apiConfig.backend.users.login, data: {} })
      .then((res) => res)
      .catch((err) => { throw err })
  }

  @Action({ rawError: true })
  async [AuthActions.GET_AUTH_USERDATA] (apiToken = '') { // eslint-disable-line class-methods-use-this
    console.log('[VUEX :: Auth] GET_AUTH_USERDATA')
    const { useApi } = useApiAsync({ apiToken })
    // eslint-disable-next-line no-restricted-syntax, no-labels, no-unused-labels
    return await useApi({ api: apiConfig.backend.users.get, data: {} }).then((res) => res).catch((err) => { error: err }) // eslint-disable-line max-len
  }

  @Mutation
  [Mutations.SET_ERROR] (error) {
    console.log('[VUEX :: Auth] SET_ERROR')
    this.errors = error
  }

  @Mutation
  [Mutations.SET_AUTH] (user) {
    console.log('[VUEX :: Auth] SET_AUTH', user)
    this.isAuthenticated = true
    this.user = user
    this.errors = {}
  }

  @Mutation
  [AuthMutations.SET_DATA] (data) {
    console.log('[VUEX :: Auth] SET_DATA', data)
    this.data = data
  }

  @Mutation
  [Mutations.PURGE_AUTH] () {
    this.isAuthenticated = false
    this.user = {}
    this.errors = {}
    this.data = null
    this.isReady = false
  }

  @Mutation
  [AuthMutations.SET_AUTH_READY] () {
    this.isReady = true
  }

  @Mutation
  [AuthMutations.SET_NEW_TOKEN] (token) {
    this.user.accessToken = token
  }
}
