// ToolbarModule.ts
// Custom NYG module
import defaultToolbarValues from '@src/core/config/DefaultToolbarValues' // Modified file
import { ToolbarActions, ToolbarGetters, ToolbarMutations } from '@src/store/enums/ToolbarEnums'
import { Mutation, Module, VuexModule, Action } from 'vuex-module-decorators'
import { ToolbarData } from '@src/core/config/ToolbarTypes'

interface StoreInfo {
  submenu: ToolbarData,
  initial: ToolbarData
}

@Module
export default class ToolbarModule extends VuexModule implements StoreInfo {
  submenu: ToolbarData = defaultToolbarValues
  initial: ToolbarData = defaultToolbarValues

  get [ToolbarGetters.GET_ACTIVE_TOOLBAR] () {
    return {
      name: `${ this.submenu.name }`,
      data: this.submenu.data
    }
  }

  @Mutation
  [ToolbarMutations.SET_ACTIVE_TOOLBAR] (toolbarData: any): void {
    const name = toolbarData.name !== undefined ? toolbarData.name : 'DefaultToolbar'
    const data = toolbarData.data !== undefined ? toolbarData.data : {}

    this.submenu = { ...this.initial, ...{ name, data } }
  }

  @Action
  [ToolbarActions.SET_ACTIVE_TOOLBAR] (toolbarData): void {
    const name = toolbarData.name !== undefined ? toolbarData.name : 'DefaultToolbar'
    const data = toolbarData.data !== undefined ? toolbarData.data : {}

    this.context.commit(ToolbarMutations.SET_ACTIVE_TOOLBAR, { name, data })
  }
}
